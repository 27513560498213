import { CSPConfig } from 'types/csp';
import isBoolean from 'lodash/isBoolean';

export const baseCSP: CSPConfig = {
  'default-src': ["'self'", 'https://fonts.googleapis.com'],
  'script-src': [
    "'self'",
    "'unsafe-inline'",
    '*.cloudfront.net',
    'https://cdn.jsdelivr.net',
    'https://www.google-analytics.com',
    'https://www.googletagmanager.com',
  ],
  'worker-src': ['blob:'],
  'style-src': [
    "'self'",
    "'unsafe-inline'",
    '*.cloudfront.net',
    'https://cdn.jsdelivr.net',
    'https://fonts.googleapis.com',
    'https://www.google-analytics.com',
  ],
  'img-src': [
    "'self'",
    'blob:',
    'data:',
    '*.cloudfront.net',
    'https://www.google-analytics.com',
    `${process.env.IMGIX_SOURCE}`,
  ],
  'font-src': [
    "'self'",
    'data:',
    '*.cloudfront.net',
    'https://fonts.gstatic.com',
  ],
  'object-src': ["'self'"],
  'base-uri': ["'self'"],
  'form-action': ["'self'"],
  'frame-ancestors': ["'self'"],
  'upgrade-insecure-requests': true,
  'connect-src': ["'self'", '*.sentry.io', 'https://www.google-analytics.com'],
  'frame-src': ["'self'"],
  'media-src': ["'self'"],
  'child-src': ["'none'"],
  'block-all-mixed-content': true,
};

function addUniqueSources(
  directive: string[],
  sourcesToAdd: string[],
): string[] {
  const sourceSet = new Set(directive);
  sourcesToAdd.forEach((source) => sourceSet.add(source));
  return Array.from(sourceSet);
}

export function getCSPPolicy(): string {
  let extendedCSP = { ...baseCSP };

  // Extend base CSP with region specific whitelist
  switch (process.env.NODE_ENV) {
    case 'development':
      break;

    case 'production':
      // TODO: can be deleted later
      // extendedCSP["script-src"] = addUniqueSources(extendedCSP["script-src"], [
      //   "https://cdn.example.com"
      // ]);
      break;

    default:
      break;
  }

  const cspString = Object.entries(extendedCSP)
    .map(([directive, sources]) =>
      isBoolean(sources) ? `${directive}` : `${directive} ${sources.join(' ')}`,
    )
    .join('; ');

  return cspString;
}
