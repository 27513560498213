import { NextComponentType, NextPageContext } from 'next';
import React, { Component } from 'react';
import { fetchFeatures } from 'actions/features';
import { getCSPPolicy } from 'lib/helpers/csp';

interface PageContext extends NextPageContext {
  reduxStore: any;
}

const withCSPHeader = (App: NextComponentType) =>
  class withCSPHeader extends Component {
    static async getInitialProps(ctx: PageContext) {
      // fetch features if not present
      const { features } = ctx.reduxStore.getState();
      const isFeaturesPresent = features.doneFetching && !features.fetchError;
      if (!isFeaturesPresent) {
        await ctx.reduxStore.dispatch(fetchFeatures());
      }

      const contentSecurityPolicyReportOnlyBluehaven =
        ctx.reduxStore.getState().features.features[
          'content_security_policy_report_only_bluehaven'
        ];

      const contentSecurityPolicyBluehaven =
        ctx.reduxStore.getState().features.features[
          'content_security_policy_bluehaven'
        ];

      const contentSecurityPolicy = getCSPPolicy();
      const cspReportUrl =
        process.env.NODE_ENV === 'development'
          ? null
          : '/platform/csp_reports?app=blue-haven';

      if (contentSecurityPolicyReportOnlyBluehaven?.enabled) {
        ctx.res.setHeader(
          'Content-Security-Policy-Report-Only',
          `report-uri ${cspReportUrl};` + contentSecurityPolicy,
        );
      }

      if (contentSecurityPolicyBluehaven?.enabled) {
        ctx.res.setHeader('Content-Security-Policy', contentSecurityPolicy);
      }

      let appProps = {};

      if (typeof App.getInitialProps === 'function') {
        appProps = await App.getInitialProps(ctx);
      }

      return {
        ...appProps,
      };
    }

    render() {
      return <App {...this.props} />;
    }
  };

export default withCSPHeader;
